import styled from "styled-components";
import { Text } from "@new-ui-kit";
import breakpoints from "@styles/breakpoints";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  width: 100%;
  height: 120px;
  max-width: 320px;
  padding: 16px 24px;
  background: ${({ theme }) => theme.bg.cards};
  border: 1px solid ${({ theme }) => theme.stroke.default};
  border-radius: 10px;
  @media ${breakpoints.tablet} {
    padding: 16px;
  }
  @media ${breakpoints.mobile} {
    height: 98px;
    max-width: 350px;
  }
`;

const MainPageWrapper = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  margin: 0;
  @media ${breakpoints.tablet} {
    margin-top: 24px;
  }
  @media ${breakpoints.mobile} {
    margin-top: 16px;
  }
`;

const BlockListContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  gap: 24px;
  width: 100%;
  @media ${breakpoints.tablet} {
    gap: 16px;
  }
  @media ${breakpoints.mobile} {
    flex-direction: column;
  }
`;

const StyledBlockValue = styled(Text)`
  font-weight: 400;
  font-size: 26px;
  @media ${breakpoints.tablet} {
    font-size: 22px;
  }
`;

const DiscountWrapper = styled.div<{ background: string }>`
  background: ${({ background }) => background};
  border-radius: 6px;
  padding: 4px 8px;
  margin-top: 10px;
  display: flex;
  align-items: center;
`;
export const blockStyles = {
  Container,
  StyledBlockValue,
  MainPageWrapper,
  DiscountWrapper,
  BlockListContainer,
};

import styled from "styled-components";
import { Popover } from "@mui/material";
import { PopoverContainerPropsInterface as Props } from "./types";
import { COMMON_BOX_SHADOW } from "@styles/GlobalStyle";

const Container = styled(Popover)<Props>`
  .MuiPopover-paper {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    height: ${({ height }) => height};
    max-height: ${({ $dropdownMaxHeight }) => $dropdownMaxHeight};
    width: ${({ width }) => width};
    background: ${({ theme, $noBackground }) =>
      $noBackground ? "inherit" : theme.white};
    border: ${({ $noBackground, theme }) =>
      $noBackground ? "none" : `1px solid ${theme.stroke.default}`};
    -webkit-box-shadow: ${({ $noBackground }) =>
      $noBackground ? "none" : `${COMMON_BOX_SHADOW}`};
    box-shadow: ${({ $noBackground }) =>
      $noBackground ? "none" : `${COMMON_BOX_SHADOW}`};
    border-radius: ${({ $borderRadius }) => `${$borderRadius}px`};
    padding: 0;
    margin-top: ${({ $isDropdown }) => ($isDropdown ? "8px" : "0px")};
    overflow-x: hidden;
    overflow-y: ${({ $isScroll }) => ($isScroll ? "visible" : "hidden")};
    &::-webkit-scrollbar {
      background: transparent;
      position: relative;
      width: 4px;
    }
    &::-webkit-scrollbar-thumb {
      position: absolute;
      height: 48px;
      width: 4px;
      background: #e4e4e7;
      border-radius: 4px;
    }
  }
`;

export const popoverStyles = { Container };

const monthFormatter = [
  {
    id: 1,
    smallName: "Янв",
    fullName: "Январь",
  },
  {
    id: 4,
    smallName: "Апр",
    fullName: "Апрель",
  },
  {
    id: 7,
    smallName: "Июль",
    fullName: "Июль",
  },
  {
    id: 10,
    smallName: "Окт",
    fullName: "Октябрь",
  },
  {
    id: 2,
    smallName: "Фев",
    fullName: "Февраль",
  },
  {
    id: 5,
    smallName: "Май",
    fullName: "Май",
  },
  {
    id: 8,
    smallName: "Авг",
    fullName: "Август",
  },
  {
    id: 11,
    smallName: "Нояб",
    fullName: "Ноябрь",
  },
  {
    id: 3,
    smallName: "Март",
    fullName: "Март",
  },
  {
    id: 6,
    smallName: "Июнь",
    fullName: "Июнь",
  },
  {
    id: 9,
    smallName: "Сент",
    fullName: "Сентябрь",
  },
  {
    id: 12,
    smallName: "Дек",
    fullName: "Декабрь",
  },
];
const monthList = [
  [
    {
      id: 1,
      smallName: "Янв",
      fullName: "Январь",
    },
    {
      id: 4,
      smallName: "Апр",
      fullName: "Апрель",
    },
    {
      id: 7,
      smallName: "Июль",
      fullName: "Июль",
    },
    {
      id: 10,
      smallName: "Окт",
      fullName: "Октябрь",
    },
  ],
  [
    {
      id: 2,
      smallName: "Фев",
      fullName: "Февраль",
    },
    {
      id: 5,
      smallName: "Май",
      fullName: "Май",
    },
    {
      id: 8,
      smallName: "Авг",
      fullName: "Август",
    },
    {
      id: 11,
      smallName: "Нояб",
      fullName: "Ноябрь",
    },
  ],
  [
    {
      id: 3,
      smallName: "Март",
      fullName: "Март",
    },
    {
      id: 6,
      smallName: "Июнь",
      fullName: "Июнь",
    },
    {
      id: 9,
      smallName: "Сент",
      fullName: "Сентябрь",
    },
    {
      id: 12,
      smallName: "Дек",
      fullName: "Декабрь",
    },
  ],
];

export const calendarConfig = { monthList, monthFormatter };

export const DIVIDER = 8;
export const maxValueFormatter = (maxValue: number) => {
  if (typeof maxValue !== "number" || maxValue <= 0) {
    return {
      step: 0,
      max: 0,
    };
  }
  const tenthNumber = Math.round(maxValue / DIVIDER);

  const tenthNumberArray = ("" + tenthNumber).split("").map(Number);
  const tenthNumberArrayWithoutFirthNumber = [...tenthNumberArray];
  tenthNumberArrayWithoutFirthNumber.shift();
  const roundedTenthNumber =
    tenthNumberArray.length > 1
      ? Math.round(
          Number(
            tenthNumberArray[0]?.toString() +
              "." +
              tenthNumberArray[1]?.toString()
          )
        )
      : tenthNumberArray[0];
  const formattedTenthNumber = Number(
    roundedTenthNumber +
      tenthNumberArrayWithoutFirthNumber.map(() => 0).join("")
  );

  const max =
    formattedTenthNumber > maxValue
      ? formattedTenthNumber * 9
      : formattedTenthNumber > maxValue
      ? formattedTenthNumber * 10
      : formattedTenthNumber > maxValue
      ? formattedTenthNumber * 11
      : formattedTenthNumber > maxValue
      ? formattedTenthNumber * 12
      : formattedTenthNumber * 13;

  return {
    step: formattedTenthNumber,
    max: max,
  };
};

export const getOptions = (maxValue: number) => {
  return {
    maintainAspectRatio: false,
    responsive: true,
    interaction: {
      mode: "index" as const,
      intersect: false,
    },
    plugins: {
      colors: {
        forceOverride: true,
      },
      tooltip: {
        yAlign: "bottom" as const,
        backgroundColor: "#fff",
        titleColor: "#111827",
        bodyColor: "#111827",
        titleFont: {
          weight: "400",
          size: 12,
        },
        titleAlign: "center" as const,
        bodyFont: {
          weight: "400",
          size: 16,
        },
        bodyAlign: "center" as const,
        footerColor: "red",
        padding: 12,
        cornerRadius: 10,
        borderColor: "#E4E4E7",
        borderWidth: 1,
        boxWidth: 0,
        usePointStyle: true,
        boxHeight: 0,
        callbacks: {
          title: () => "",
        },
      },
      legend: {
        position: "bottom" as const,
        align: "start" as const,
        display: true,
        fontColor: "#111827",
        labels: {
          font: {
            size: 14,
          },
          color: "#111827",
          boxWidth: 4,
          boxHeight: 4,
          usePointStyle: true,
          pointStyle: "circle",
          padding: 12,
        },
      },
      title: {
        display: true,
        position: "top" as const,
        align: "start" as const,
        text: "Звонки",
        color: "#111827",
        font: {
          size: 19,
          weight: "400",
        },
        padding: {
          bottom: 30,
        },
      },
    },
    scales: {
      x: {
        grid: {
          display: false,
        },
      },
      y: {
        min: 0,
        max: maxValueFormatter(maxValue).max as number,
        ticks: {
          stepSize: maxValueFormatter(maxValue).step as number,
        },
        border: {
          dash: [4, 4],
          display: false,
        },
        grid: {
          display: true,
          borderDash: [10],
          tickBorderDash: [0, 1],
        },
      },
    },
  };
};

const getData = (
  labels: Array<number>,
  callsByDate: Array<number>,
  callsWithConnect: Array<number>,
  uniqClients: Array<number>
) => {
  return {
    labels,
    datasets: [
      {
        label: "Попытки дозвониться",
        data: callsByDate,
        borderColor: "#EAA43A",
        borderWidth: 2,
        backgroundColor: "#EAA43A",
        tension: 0.4,
        pointRadius: 0,
        pointHoverRadius: 6,
        hoverBackgroundColor: "white",
        hoverBorderColor: "#EAA43A",
        hoverBorderWidth: 3,
        fill: false,
      },
      {
        label: "Всего абонентов",
        data: uniqClients,
        borderColor: "#4F46E5",
        borderWidth: 2,
        backgroundColor: "#4F46E5",
        tension: 0.4,
        pointRadius: 0,
        pointHoverRadius: 6,
        hoverBackgroundColor: "white",
        hoverBorderColor: "#4F46E5",
        hoverBorderWidth: 3,
        fill: false,
      },
      {
        label: "Ответили на звонок",
        data: callsWithConnect,
        borderColor: "#64C66F",
        backgroundColor: "#64C66F",
        borderWidth: 2,
        pointBorderColor: "#64C66F",
        pointBorderWidth: 2,
        pointHoverRadius: 6,
        hoverBackgroundColor: "white",
        hoverBorderColor: "#64C66F",
        hoverBorderWidth: 3,
        tension: 0.4,
        pointRadius: 0,
        fill: false,
      },
    ],
  };
};

type GetDataType = typeof getData;
type ReturnTypeGetDataType = ReturnType<GetDataType>;

const checkValues = (data: ReturnTypeGetDataType): boolean => {
  const isZeroValuesArray = Array.from(
    { length: data.datasets.length },
    () => true
  );
  data.datasets.forEach((dataset, index) => {
    isZeroValuesArray[index] = dataset.data.every((elem) => elem === 0);
  });

  return isZeroValuesArray.every((elem) => elem === true);
};

export const chartConfig = { getOptions, getData, checkValues };

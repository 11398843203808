import styled from "styled-components";
import { Flex, Text } from "@new-ui-kit";
import breakpoints from "@styles/breakpoints";

const Container = styled(Flex)`
  background-color: ${({ theme }) => theme.bg.cards};
  border: ${({ theme }) => `1px solid ${theme.stroke.default}`};
  border-radius: 10px;
  @media ${breakpoints.mobile} {
    padding: 16px;
  }
`;
const ChartTitle = styled(Text)`
  font-size: 20px;
  font-weight: 400;
  line-height: normal;
  position: absolute;
  left: 24px;
`;

export const chartStyles = { Container, ChartTitle };

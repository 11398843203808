import { NextPage } from "next";
import { Flex, StyledSkeleton, Text } from "@new-ui-kit";

import { NO_DATA_TITLE } from "@constants";
import { blockStyles } from "./styles";
import { BlockPropsInterface as Props } from "./types";

const { Container, StyledBlockValue, DiscountWrapper } = blockStyles;

const Block: NextPage<Props> = ({ header, value, discount, isLoading }) => {
  const formattedDiscount = typeof discount === "number" ? discount : 0;
  const isPositive = formattedDiscount > 0;
  const isZero = formattedDiscount === 0;

  const discountTitle = isPositive
    ? `+${formattedDiscount}%`
    : `${formattedDiscount}%`;
  const discountBackgroundColor = isPositive
    ? "#CFFCCB"
    : isZero
    ? "#EEEEEF"
    : "#ffe0e0";

  return (
    <Container>
      {!isLoading && header ? (
        <Text pattern="headline3" weight={400}>
          {header}
        </Text>
      ) : (
        <StyledSkeleton width={180} height={20} variant="rectangular" />
      )}
      <Flex flexDirection="row" justifyContent="space-between" width="100%">
        <StyledBlockValue>
          {!isLoading && value === NO_DATA_TITLE ? (
            <Text pattern="body2" color="dark_gray">
              {value}
            </Text>
          ) : !isLoading && value !== NO_DATA_TITLE ? (
            value
          ) : (
            <StyledSkeleton width={120} height={24} variant="rectangular" />
          )}
        </StyledBlockValue>
        {!isLoading &&
        value !== NO_DATA_TITLE &&
        header !== "Стоимость 1 минуты" ? (
          <DiscountWrapper background={discountBackgroundColor}>
            <Text
              customColor={
                isPositive ? "#24A205" : isZero ? "#111827" : "#DD0202"
              }
              pattern="caption1"
            >
              {discountTitle}
            </Text>
          </DiscountWrapper>
        ) : (
          <></>
        )}
      </Flex>
    </Container>
  );
};

export default Block;

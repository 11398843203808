import React, { useEffect, useRef, useState } from "react";
import { NextPage } from "next";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  Filler,
} from "chart.js";
import { Line } from "react-chartjs-2";
import ChartSkeleton from "@components/screens/home/components/ChartSkeleton";
import { Text, Flex } from "@new-ui-kit";

import { chartStyles } from "./styles";
import { ChartPropsInterface as Props } from "./types";
import { chartConfig } from "./config";
import {
  CONTAINER_MARGIN,
  LEFT_RIGHT_PADDING,
  BOTTOM_PADDING,
  TOP_PADDING,
} from "./constants";
import { NO_DATA_TITLE } from "@constants";
import { If, Then } from "react-if";

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Filler,
  Legend
);

const { getOptions, getData, checkValues } = chartConfig;
const { Container, ChartTitle } = chartStyles;

const Chart: NextPage<Props> = ({
  labels,
  callsByDate,
  callsWithConnect,
  uniqClients,
  isLoading = false,
  chartSize,
}) => {
  const [showChart, setShowChart] = useState(false);
  const [isHaveLabels, setIsHaveLabels] = useState(false);
  const [contentWidth, setContentWidth] = useState(0);
  const [contentHeight, setContentHeight] = useState(0);
  const containerRef = useRef<HTMLDivElement>(null);

  const showSkeleton = Boolean(contentWidth && contentHeight);
  const containerMargin = `${CONTAINER_MARGIN}px 0px ${CONTAINER_MARGIN}px 0px`;
  const containerPadding = `${TOP_PADDING}px ${LEFT_RIGHT_PADDING}px`;
  const options = getOptions(Math.max.apply(null, callsByDate));
  const data = getData(labels, callsByDate, callsWithConnect, uniqClients);
  const isAllValuesEqualsZero = checkValues(data);

  const setChartSize = (container: HTMLDivElement) => {
    const contentWidth = container.clientWidth - 2 * LEFT_RIGHT_PADDING;
    const contentHeight =
      container.clientHeight - (TOP_PADDING + BOTTOM_PADDING);
    setContentWidth(contentWidth);
    setContentHeight(contentHeight);
  };

  const showChartHandler = () => {
    if (!isLoading) {
      setTimeout(() => {
        setShowChart(true);
      }, 200);
    } else {
      setShowChart(false);
    }
  };

  useEffect(() => {
    if (labels.length === 1 && labels[0] === 0) {
      setIsHaveLabels(false);
    } else {
      setIsHaveLabels(true);
    }
  }, [labels]);

  useEffect(() => {
    if (containerRef.current) {
      setChartSize(containerRef.current);
    }
  }, [containerRef.current]);

  useEffect(() => {
    showChartHandler();
  });

  return (
    <Container
      ref={containerRef}
      height={`${chartSize.height}px`}
      width={`${chartSize.width}px`}
      margin={containerMargin}
      padding={containerPadding}
      justifyContent="center"
    >
      <If condition={(!isHaveLabels || isAllValuesEqualsZero) && showChart}>
        <Then>
          <>
            <ChartTitle>Звонки</ChartTitle>
            <Flex alignItems="center">
              <Text color="dark_gray">{NO_DATA_TITLE}</Text>
            </Flex>
          </>
        </Then>
      </If>
      <If condition={!showChart && showSkeleton}>
        <Then>
          <ChartSkeleton width={contentWidth} height={contentHeight} />
        </Then>
      </If>
      <If condition={showChart && isHaveLabels && !isAllValuesEqualsZero}>
        <Then>
          <Line height={415} options={options} data={data} />
        </Then>
      </If>
    </Container>
  );
};

export default Chart;

import { NextPage } from "next";
import Popover from "@components/Dropdowns/Popover";
import { Input, Flex, Text, IconComponent } from "@new-ui-kit";
import CalendarDropdown from "./components/CalendarDropdown";
import { calendarConfig } from "./config";
import CalendarIcon from "@assets/inputIcons/calendar.svg";
import { useDesktopMediaQuery } from "@helpers/hooks/apaptive";
import { useEffect, useState } from "react";
import DropIcon from "@assets/inputIcons/drop.svg";
import TabletModal from "@components/Modals/TabletModal";

import { CURRENT_MONTH } from "@constants";
import { CURRENT_YEAR } from "@constants";
import { tabletModalStyles } from "@components/Modals/TabletModal/styles";

import CloseIcon from "@assets/close.svg";
import useSafeRouter from "@helpers/hooks/useSafeRouter";

const { monthFormatter } = calendarConfig;
const { TabletModalHeader } = tabletModalStyles;

const Calendar: NextPage = () => {
  const [openModal, setOpenModal] = useState(false);
  const [selectedData, setSelectedData] = useState<{
    id: number;
    yearCounter: number;
  }>({ id: CURRENT_MONTH, yearCounter: +CURRENT_YEAR });

  const { query, replace } = useSafeRouter();
  const selectedMonth = (query?.month && +query?.month) || +CURRENT_MONTH;
  const selectedYear = (query?.year && +query?.year) || +CURRENT_YEAR;

  const isDesktop = useDesktopMediaQuery();
  const value =
    monthFormatter.find((item) => item.id === Number(selectedMonth))
      ?.fullName || "";

  const [yearCounter, setYearCounter] = useState(Number(CURRENT_YEAR));

  useEffect(() => {
    setSelectedData({
      id: selectedMonth,
      yearCounter: selectedYear,
    });
  }, [openModal, selectedMonth, selectedYear]);

  useEffect(() => {
    replace({
      query: {
        year: selectedData.yearCounter,
        month: selectedData.id,
      },
    });
  }, [selectedData]);

  const openCalendarModal = () => {
    setOpenModal(true);
  };
  const closeCalendarModal = () => {
    setOpenModal(false);
  };
  const skipChangesFunc = async () => {
    setSelectedData({
      id: +CURRENT_MONTH,
      yearCounter: +CURRENT_YEAR,
    });
    setYearCounter(Number(CURRENT_YEAR));
  };
  const confirmChangesFunc = async () => {
    closeCalendarModal();
  };

  const closeModalFunc = () => {
    setSelectedData({
      id: selectedMonth,
      yearCounter: selectedYear,
    });
    closeCalendarModal();
  };

  if (isDesktop) {
    return (
      <Popover
        id="calendar"
        dropdownHeight="max-content"
        dropdownWidth="264px"
        containerWidth="264px"
        isDropdown
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
        component={({ isHover, onOpen }) => (
          <Input
            readOnly
            onClick={onOpen}
            value={value}
            id="calendarInputId"
            label="Период"
            endContent={<IconComponent Icon={CalendarIcon} hovered={isHover} />}
          />
        )}
        dropdown={({ onClose }) => (
          <CalendarDropdown
            onClose={onClose}
            setSelectedData={setSelectedData}
            selectedData={selectedData}
            setYearCounter={setYearCounter}
            yearCounter={yearCounter}
          />
        )}
      />
    );
  } else {
    return (
      <>
        <Input
          width="122px"
          readOnly
          value={value}
          label="Период"
          endContent={<IconComponent Icon={DropIcon} />}
          onClick={openCalendarModal}
          id="calendarInputId"
        />

        <TabletModal
          open={openModal}
          closeFunc={closeModalFunc}
          confirmChangesFunc={confirmChangesFunc}
          skipChangesFunc={skipChangesFunc}
          height="fit-content"
        >
          <TabletModalHeader>
            <Text pattern="bottomSheet">Период</Text>
            <IconComponent Icon={CloseIcon} onClick={closeModalFunc} pointer />
          </TabletModalHeader>

          <Flex width="256px" height="209px" justifyContent="center">
            <CalendarDropdown
              setSelectedData={setSelectedData}
              selectedData={selectedData}
              selectOnClick={false}
              yearCounter={yearCounter}
              setYearCounter={setYearCounter}
            ></CalendarDropdown>
          </Flex>
        </TabletModal>
      </>
    );
  }
};

export default Calendar;

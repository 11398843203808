import { useState } from "react";
import { NextPage } from "next";
import { Text } from "@new-ui-kit";
import { monthComponentStyles } from "./styles";
import { MonthComponentPropsInterface as Props } from "./types";
import { useTouchQuery } from "@helpers/hooks/apaptive";

const { Container } = monthComponentStyles;

const MonthComponent: NextPage<Props> = ({
  id,
  smallName,
  yearCounter,
  disabled,
  selectedData,
  close,
  setSelectedData,
  selectOnClick,
}) => {
  const [hovered, setHovered] = useState(false);
  const isTouchDevice = useTouchQuery();

  const isSelected =
    yearCounter === selectedData?.yearCounter && id === selectedData?.id;

  const textColor = disabled
    ? "light_gray"
    : hovered && selectOnClick
    ? "black"
    : isSelected
    ? "white"
    : hovered && !selectOnClick
    ? "black"
    : "black";

  const selectDateHandler = () => {
    if (setSelectedData)
      setSelectedData({
        id: Number(id),
        yearCounter: Number(yearCounter),
      });
    if (selectOnClick && close) close();
  };
  const onMouseEnter = () => {
    setHovered(true);
  };
  const onMouseLeave = () => {
    setHovered(false);
  };

  return (
    <Container
      isActive={isSelected}
      selectOnClick={selectOnClick}
      hover={hovered}
      onMouseEnter={isTouchDevice ? undefined : onMouseEnter}
      onMouseLeave={isTouchDevice ? undefined : onMouseLeave}
      onClick={selectDateHandler}
      disabled={disabled}
    >
      <Text pattern="body1" color={textColor} pointer={!disabled}>
        {smallName}
      </Text>
    </Container>
  );
};

export default MonthComponent;

import styled from "styled-components";

const TabletModalWrapper = styled.div<{ height: string }>`
  border-radius: 16px 16px 0 0;
  background: #fff;
  width: 768px;
  position: absolute;
  bottom: 0;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  height: ${({ height }) => (height ? height : "552px")};
  max-height: 80vh;
`;

const TabletModalHeader = styled.div`
  width: 100%;
  display: flex;
  height: 48px;
  justify-content: space-between;
  padding: 12px 16px;
  border-bottom: 1px solid #e4e4e7;
  background: var(--colors-bg-cards, #fff);
  border-radius: 16px 16px 0 0;
`;

const TabletModalButtons = styled.div<{ hasShadow?: boolean }>`
  display: flex;
  width: 100%;
  padding: 16px;
  justify-content: center;
  align-items: center;
  gap: 16px;
  box-shadow: ${({ hasShadow }) =>
    hasShadow ? "0px -4px 16px 0 rgba(100, 100, 100, 0.08)" : "none"};
`;
const SecondPageImitationLine = styled.div`
  position: absolute;
  height: 13px;
  width: 90%;
  background: #e5e5e5;
  border-radius: 16px 16px 0px 0px;
  top: -11.5px;
  transform: perspective(1000px) rotateX(40deg);
`;

export const tabletModalStyles = {
  TabletModalWrapper,
  TabletModalHeader,
  TabletModalButtons,
  SecondPageImitationLine,
};

import { ReactNode, useEffect, useRef, useState } from "react";
import { NextPage } from "next";
import { modalStyles } from "src/ui-kit/Modal/styles";
import { tabletModalStyles } from "./styles";
import { Button, Flex, Modal } from "@new-ui-kit";
import { useHotkeys } from "react-hotkeys-hook";
import { modalElementsHeight } from "@constants";
import { MAX_MODAL_HEIGHT } from "@constants";
import { useOutsideClick } from "@helpers/hooks/useOutsideClick";

const { ModalWrapper } = modalStyles;
const { TabletModalWrapper, TabletModalButtons } = tabletModalStyles;

const TabletModal: NextPage<{
  children: ReactNode;
  open: boolean;
  closeFunc: () => void;
  skipChangesFunc: () => void;
  confirmChangesFunc: () => void;
  buttonType?: "button" | "submit" | "reset" | undefined;
  isScrollable?: boolean;
  isButtons?: boolean;
  height?: string;
  listLength?: number;
  className?: string;
}> = ({
  children,
  open,
  closeFunc,
  skipChangesFunc,
  confirmChangesFunc,
  buttonType,
  isScrollable = false,
  height,
  listLength,
  className,
  isButtons = true,
}) => {
  const skipChanges = () => {
    skipChangesFunc();
  };
  const confirmChanges = () => {
    confirmChangesFunc();
    closeFunc();
  };
  const outsideFunc = () => {
    if (open) closeFunc();
  };
  const [tabletModalHeight, setTabletModalHeight] = useState(0);
  const TabletModalRef = useRef<HTMLDivElement | null>(null);

  useOutsideClick(TabletModalRef, outsideFunc);

  useHotkeys("escape", outsideFunc);

  useEffect(() => {
    if (listLength) {
      const isSearchInModal =
        TabletModalRef.current?.querySelector(".modalSearch");
      const fullModalHeight =
        listLength * 48 +
        modalElementsHeight.headerHeight +
        modalElementsHeight.buttonsHeight +
        (isSearchInModal ? modalElementsHeight.searchHeight : 0);

      if (fullModalHeight > MAX_MODAL_HEIGHT) {
        setTabletModalHeight(MAX_MODAL_HEIGHT);
      } else {
        setTabletModalHeight(fullModalHeight);
      }
    } else {
      setTabletModalHeight(
        modalElementsHeight.headerHeight +
          modalElementsHeight.buttonsHeight +
          150
      );
    }
  }, [open, listLength]);

  return (
    <Modal active={open} onCancel={outsideFunc} isTabletModal>
      <ModalWrapper>
        <TabletModalWrapper
          height={height ? height : tabletModalHeight + "px"}
          ref={TabletModalRef}
          className={className}
        >
          {children}

          {isButtons ? (
            <TabletModalButtons hasShadow={isScrollable}>
              <Flex gap="16px" width="100%">
                <Button
                  pattern="secondary"
                  fullWidth
                  size="normal"
                  type={buttonType}
                  onClick={skipChanges}
                >
                  Сбросить
                </Button>
                <Button
                  type={buttonType}
                  pattern="primary"
                  fullWidth
                  size="normal"
                  onClick={confirmChanges}
                >
                  Применить
                </Button>
              </Flex>
            </TabletModalButtons>
          ) : null}
        </TabletModalWrapper>
      </ModalWrapper>
    </Modal>
  );
};

export default TabletModal;

import { NextPage } from "next";
import { Flex, Text, IconComponent } from "@new-ui-kit";

import MonthComponent from "../MonthComponent";
import { isFutureMonthYear } from "@utils";
import { CalendarDropdownPropsInterface as Props } from "./types";
import { calendarConfig } from "../../config";
import LeftIcon from "@assets/ChevronLeft.svg";
import RightIcon from "@assets/ChevronRight.svg";

const { monthList } = calendarConfig;

const CalendarDropdown: NextPage<Props> = ({
  onClose,
  selectOnClick = true,
  selectedData,
  setSelectedData,
  setYearCounter,
  yearCounter,
}) => {
  const prevYear = () => setYearCounter((prev) => --prev);
  const nexYear = () => setYearCounter((prev) => ++prev);

  return (
    <Flex
      padding="24px"
      width="100%"
      gap="16px"
      flexDirection="column"
      justifyContent="flex-start"
    >
      <Flex
        width="100%"
        height="24px"
        flexDirection="row"
        justifyContent="space-between"
        alignItems="center"
      >
        <IconComponent Icon={LeftIcon} pointer onClick={prevYear} />
        <Text pattern="body1" weight={500}>
          {yearCounter}
        </Text>
        <IconComponent Icon={RightIcon} pointer onClick={nexYear} />
      </Flex>
      <Flex
        flexDirection="row"
        justifyContent="space-between"
        height="152px"
        width="100%"
      >
        {monthList.map((column, index) => (
          <Flex
            key={index}
            flexDirection="column"
            justifyContent="space-between"
            alignItems="center"
          >
            {column.map((item) => (
              <MonthComponent
                key={item.id}
                yearCounter={yearCounter}
                close={onClose}
                disabled={isFutureMonthYear(item.id, yearCounter)}
                setSelectedData={setSelectedData}
                selectedData={selectedData}
                selectOnClick={selectOnClick}
                {...item}
              />
            ))}
          </Flex>
        ))}
      </Flex>
    </Flex>
  );
};

export default CalendarDropdown;

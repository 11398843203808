import { useEffect, useRef, useState } from "react";
import { NextPage } from "next";
import { withLayout } from "layout";
import { useGetDashboardsQuery } from "@features/dashboards/dashboards-api-slice";
import { selectAuth, selectCompanyCurrency } from "@features/user/user-slice";
import {
  useMobileMediaQuery,
  useTabletAndBelowMediaQuery,
} from "@helpers/hooks/apaptive";
import Block from "@components/screens/home/components/StaticticBlock";
import { homePageConfig } from "@components/screens/home/config";
import "react-toastify/dist/ReactToastify.css";
import { IDashboardsResponce } from "@features/types";
import Calendar from "@components/Calendar";
import { errorNotify, Flex } from "@new-ui-kit";
import { blockStyles } from "@components/screens/home/components/StaticticBlock/styles";
import { useAppSelector } from "@helpers/hooks/redux";
import Chart from "@components/screens/home/components/MainChart";
import useSafeRouter from "@helpers/hooks/useSafeRouter";

const { BlockListContainer, MainPageWrapper } = blockStyles;

const { getDays, getValues, chartCurrencyFormatter } = homePageConfig;

const Home: NextPage = () => {
  const [chartSize, setChartSize] = useState({
    height: 0,
    width: 0,
  });

  const isTabletOrMobile = useTabletAndBelowMediaQuery();
  const isMobile = useMobileMediaQuery();

  const { query, replace } = useSafeRouter();
  const chartContainerRef = useRef<HTMLDivElement>(null);
  const selectedYear = query?.year as string;
  const selectedMonth = query?.month as string;
  const hadSelectedDate = Boolean(selectedYear && selectedMonth);
  const isAuth = useAppSelector((state) => selectAuth(state));
  const skipQueryDashboard = !isAuth || !hadSelectedDate;

  const [chartData, setChartData] = useState<IDashboardsResponce[] | null>(
    null
  );
  const companyCurrency = useAppSelector((state) =>
    selectCompanyCurrency(state)
  );

  const {
    data,
    refetch,
    isLoading,
    isFetching,
    isSuccess,
    isError,
    error: errorData,
  } = useGetDashboardsQuery(
    {
      month: selectedMonth,
      year: selectedYear,
    },
    {
      skip: skipQueryDashboard,
      pollingInterval: 300000,
    }
  );

  useEffect(() => {
    if (!data && isError && !isLoading) {
      if ("status" in errorData && errorData.status === 429) {
        errorNotify(
          "Ошибка при получении данных",
          "Превышен лимит запросов. Пожалуйста, повторите попытку через несколько минут.",
          "getCallsErrorLimit"
        );
      } else {
        errorNotify(
          "Ошибка",
          "Что-то пошло не так, пожалуйста повторите снова",
          "getCallsError"
        );
      }
    }
  }, [data, isError, isLoading, errorData]);

  useEffect(() => {
    if (data && selectedMonth && selectedYear) refetch();
  }, [selectedMonth, selectedYear]);

  const isLoadedData =
    isSuccess && data && !isLoading && !isFetching && hadSelectedDate;
  const isLoadingData = isLoading || isFetching;

  const hadDate =
    chartData !== null && chartData !== undefined && chartData?.length > 0;

  const chartLabels = hadDate ? getDays(chartData[0]) : [0];
  const callsByDate = hadDate ? getValues(chartData[0]?.calls_by_date) : [0];
  const callsWithConnect = hadDate
    ? getValues(chartData[0]?.calls_with_connect)
    : [0];
  const uniqClients = hadDate ? getValues(chartData[0].uniq_client) : [0];

  const costOfOneMinute = chartCurrencyFormatter(
    chartData,
    "cost_of_one_minute",
    companyCurrency
  );
  const callsDuration = chartCurrencyFormatter(
    chartData,
    "calls_duration",
    companyCurrency
  );
  const price = chartCurrencyFormatter(chartData, "price", companyCurrency);
  const callsDurationChange = hadDate
    ? chartData[0]?.calls_duration_change
    : null;
  const priceChange = hadDate ? chartData[0]?.price_change : null;

  const blockList = [
    {
      header: "Стоимость 1 минуты",
      value: costOfOneMinute,
    },
    {
      header: "Минут потрачено",
      value: callsDuration,
      discount: callsDurationChange,
    },
    {
      header: "Расходы",
      value: price,
      discount: priceChange,
    },
  ];

  const handleChartData = () => {
    if (isLoadingData) {
      setChartData(null);
    }
    if (isLoadedData) {
      setChartData(data);
    }
  };

  const handleChartSize = (container: HTMLDivElement) => {
    const width = container.clientWidth;
    const height = isMobile
      ? 368
      : isTabletOrMobile
      ? 687
      : (container.clientWidth / 10) * 4;
    setChartSize({
      width,
      height,
    });
  };

  useEffect(() => {
    handleChartData();
  }, [isLoadedData, hadSelectedDate, isLoadingData]);

  useEffect(() => {
    if (chartContainerRef.current) {
      handleChartSize(chartContainerRef.current);
    }
  }, [chartContainerRef.current]);

  return (
    <MainPageWrapper>
      <Calendar />
      <Flex
        ref={chartContainerRef}
        flexDirection="column"
        width="100%"
        height="100%"
        margin="23px 0 0 0"
      >
        <BlockListContainer>
          {blockList.map(({ ...props }, index) => (
            <Block key={index} isLoading={isLoadingData} {...props} />
          ))}
        </BlockListContainer>
        <Chart
          chartSize={chartSize}
          labels={chartLabels}
          callsByDate={callsByDate}
          callsWithConnect={callsWithConnect}
          uniqClients={uniqClients}
          isLoading={isLoadingData}
        />
      </Flex>
    </MainPageWrapper>
  );
};

export default withLayout(Home);

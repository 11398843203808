import { Currency, ICallData, IDashboardsResponce } from "@features/types";
import { NO_DATA_TITLE } from "@constants";

const getDays = (data: IDashboardsResponce) => {
  return Array.from(
    { length: data?.calls_by_date?.length },
    (_, index) => index + 1
  );
};

const getValues = (data: Array<ICallData>) => {
  const array = data.map((item) => {
    if (item.calls) {
      return item.calls;
    } else {
      return 0;
    }
  });
  return array;
};

const currencySym: Record<Currency, string> = {
  RUB: "₽",
  KZT: "₸",
};

type CurrencyFormaterType = (
  chartData: IDashboardsResponce[] | null,
  label: "cost_of_one_minute" | "calls_duration" | "price",
  currency: Currency
) => string;

const chartCurrencyFormatter: CurrencyFormaterType = (
  chartData,
  label,
  currency = "RUB"
) => {
  const data = chartData && chartData.length > 0 && chartData[0];
  const number = data ? data[label] : "";
  const title = number
    ? `${number?.toLocaleString("ru-RU")}${
        label !== "calls_duration" ? ` ${currencySym[currency]}` : ""
      }`
    : NO_DATA_TITLE;

  return title;
};

export const homePageConfig = {
  getDays,
  getValues,
  chartCurrencyFormatter,
};

import styled from "styled-components";
import { MonthComponentContainerPropsInterface as Props } from "./types";

export const Container = styled.button<Props>`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  height: 32px;
  width: 64px;
  padding: 4px 8px;
  transition: 0.2s ease-in-out;
  background: ${({ isActive, hover, theme, selectOnClick }) =>
    hover && selectOnClick
      ? theme.lilac_light
      : isActive
      ? theme.main_violet
      : hover && !selectOnClick
      ? theme.lilac_light
      : theme.bg.cards};
  border-radius: 6px;
  &:active {
    background: ${({ theme }) => theme.lilac};
  }
  &:disabled {
    cursor: not-allowed;
    background: ${({ theme }) => theme.white};
  }
`;

export const monthComponentStyles = { Container };

import { MouseEvent, useRef, useState } from "react";
import { NextPage } from "next";
import { Flex } from "@new-ui-kit";
import { popoverStyles } from "./styles";
import { PopoverProps, PopoverPropsInterface as Props } from "./types";
import { useTouchQuery } from "@helpers/hooks/apaptive";
import { useOutsideClick } from "@helpers/hooks/useOutsideClick";

const { Container } = popoverStyles;

const Popover: NextPage<Props> = ({
  id: componentId,
  componentWidth = "100%",
  dropdownWidth = "100%",
  dropdownHeight = "100%",
  dropdownMaxHeight = "auto",
  component: Component,
  dropdown: Dropdown,
  borderRadius = 10,
  isDropdown = false,
  noBackground = false,
  anchorOrigin = {
    vertical: "bottom",
    horizontal: "right",
  },
  transformOrigin = {
    vertical: "top",
    horizontal: "center",
  },
  isScroll = false,
  disableScrollLock = false,
  containerWidth,
}) => {
  const [anchorEl, setAnchorEl] = useState<HTMLDivElement | null>(null);
  const [isHover, setHover] = useState(false);

  const ref = useRef<HTMLDivElement | null>(null);
  const innerRef = useRef<HTMLDivElement | null>(null);

  const isOpen = Boolean(anchorEl);
  const isTouchDevice = useTouchQuery();
  const id = isOpen ? `popover-${componentId}` : undefined;

  const openPopover = (event?: MouseEvent<HTMLDivElement>) =>
    setAnchorEl(event?.currentTarget || innerRef.current);

  const closePopover = () => {
    const removeFocusButton = document.getElementById("reports_sessions-input");
    if (removeFocusButton) removeFocusButton.blur();

    setAnchorEl(null);
  };

  useOutsideClick(ref, closePopover);

  const props: PopoverProps = {
    isOpen,
    isHover,
    onOpen: openPopover,
    onClose: closePopover,
  };
  const onMouseEnter = () => {
    setHover(true);
  };
  const onMouseLeave = () => {
    setHover(false);
  };

  return (
    <div ref={ref}>
      <div
        style={{ width: containerWidth ? containerWidth : "initial" }}
        ref={innerRef}
        aria-describedby={id}
        onMouseEnter={isTouchDevice ? undefined : onMouseEnter}
        onMouseLeave={isTouchDevice ? undefined : onMouseLeave}
      >
        <Flex width={componentWidth} height="100%">
          {Component(props)}
        </Flex>
      </div>
      <Container
        id={id}
        open={isOpen}
        ref={ref}
        $isDropdown={isDropdown}
        height={dropdownHeight}
        onClose={closePopover}
        anchorEl={anchorEl}
        width={dropdownWidth}
        $dropdownMaxHeight={dropdownMaxHeight}
        $borderRadius={borderRadius}
        anchorOrigin={anchorOrigin}
        transformOrigin={transformOrigin}
        disableScrollLock={disableScrollLock}
        $isScroll={isScroll}
        $noBackground={noBackground}
      >
        {Dropdown(props)}
      </Container>
    </div>
  );
};

export default Popover;

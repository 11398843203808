import { NextPage } from "next";
import { Flex, StyledSkeleton } from "@new-ui-kit";
import { ChartSkeletonPropsInterface } from "./types";
import {
  HEADER_HEIGHT,
  HEADER_MARGIN_BOTTOM,
  SMALL_ROWS_COLUMN_PADDING,
  SMALL_SKELETON_ROW_HEIGHT,
  SMALL_SKELETON_ROW_WIDTH,
} from "@components/screens/home/components/MainChart/constants";

const ChartSkeleton: NextPage<ChartSkeletonPropsInterface> = ({
  width,
  height,
}) => {
  const headerMargin = `0px 0px ${HEADER_MARGIN_BOTTOM}px 0px`;
  const chartSkeletonHeight = height - HEADER_MARGIN_BOTTOM - HEADER_HEIGHT;
  const chartSkeletonWidth =
    width - SMALL_SKELETON_ROW_WIDTH - 2 * SMALL_ROWS_COLUMN_PADDING;
  const smallRows = [...Array(9)];
  const smallRowsColumnPadding = `${SMALL_ROWS_COLUMN_PADDING}px ${SMALL_ROWS_COLUMN_PADDING}px ${SMALL_ROWS_COLUMN_PADDING}px ${SMALL_ROWS_COLUMN_PADDING}px`;

  return (
    <Flex flexDirection="column" width="100%" height="100%">
      <Flex flexDirection="row" width="100%" margin={headerMargin}>
        <StyledSkeleton
          width={240}
          height={HEADER_HEIGHT}
          variant="rectangular"
        />
      </Flex>
      <Flex flexDirection="row" width="100%" height="100%">
        <Flex
          flexDirection="column"
          height="100%"
          justifyContent="space-between"
          padding={smallRowsColumnPadding}
        >
          {smallRows.map((_, index) => (
            <StyledSkeleton
              key={index}
              width={SMALL_SKELETON_ROW_WIDTH}
              height={SMALL_SKELETON_ROW_HEIGHT}
              variant="rectangular"
            />
          ))}
        </Flex>
        <Flex width="100%" height="100%">
          <StyledSkeleton
            width={chartSkeletonWidth}
            height={chartSkeletonHeight}
            variant="rectangular"
          />
        </Flex>
      </Flex>
    </Flex>
  );
};

export default ChartSkeleton;
